<template>
  <v-combobox
      label="Выберите тектоническую приуроченность"
      v-model="selected"
      item-text="name"
      :items="items"
      :rules="rules"
      @change="itemSelected($event)"
  ></v-combobox>
</template>

<script>
import selectorMixin from "@/components/modal/selector/mixin/selector-mixin";
import {mapState} from "vuex";

export default {
  mixins: [selectorMixin],
  name: "TectonicSelector",
  computed: {
    ...mapState({
      items: state => state.navigation.items,
    })
  },
}
</script>